import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import ContextProvider from "./Context/ContextOPen";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ContextChatProvider from "./Context/ContextChat";
import { Toaster } from "sonner";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextChatProvider>
    <ContextProvider>
      <HashRouter>
        <App />
        <Toaster />
      </HashRouter>
    </ContextProvider>
  </ContextChatProvider>
);
