import * as icons from "./exportIcons";

import { content } from "./translate";
import favatar_1 from "../images/Femaleavatar_1.svg";
import favatar_2 from "../images/Femaleavatar_2.svg";
import favatar_3 from "../images/Femaleavatar_3.svg";
import favatar_4 from "../images/Femaleavatar_4.svg";
import mavatar_1 from "../images/Maleavatar_1.svg";
import mavatar_2 from "../images/Maleavatar_2.svg";
import mavatar_3 from "../images/Maleavatar_3.svg";
import mavatar_4 from "../images/Maleavatar_4.svg";
import { BiPackage } from "react-icons/bi";
import { PiPackageFill } from "react-icons/pi";
export const ListSideBarUser = [
  {
    name: content.MyProfile,
    icon: icons.FaUser,
    link: "/profile",
    notification_name: "",
  },
  {
    name: content.Packages,
    icon: PiPackageFill,
    link: "/active-packages",
    notification_name: "",
  },
  {
    name: content.WhoVisitedMyAccount,
    icon: icons.BiSolidUserVoice,
    link: "/who-visited-my-account",
    notification_name: "PROFILE_VISITING",
  },
  {
    name: content.ListOfInterest,
    icon: icons.RiUserHeartFill,
    link: "/list-of-interest",
    notification_name: "",
  },
  {
    name: content.ListOfIgnore,
    icon: icons.RiUserForbidFill,
    link: "/list-of-ignore",
    notification_name: "IGNORED",
  },
  {
    name: content.WhoCaresAboutMe,
    icon: icons.BsChatHeartFill,
    link: "/who-cares-about-me",
    notification_name: "CARED",
  },
  {
    name: content.Images,
    icon: icons.IoMdPhotos,
    link: "/user-images/my-images",
    notification_name: "IMAGE_ACCESS",
  },
  {
    name: content.Settings,
    icon: icons.IoMdSettings,
    link: "/settings/",
    notification_name: "",
  },
];


export const dataPageMembers = [
  {
    path: "online",
    category: "/online",
    title: "OnlineMembers",
  },
  {
    path: "new",
    category: "/new",
    title: "NewMembers",
  },
  {
    path: "active",
    category: "/active",
    title: "LastEnteredMembers",
  },
  {
    path: "health-conditions",
    category: "/health-status",
    title: "HealthConditions",
  },
  {
    path: "members",
    category: "",
    title: "AllMembers",
  },
];
export const WhoWeAre = [
  {
    name: content.AboutUs,
    link: "/about-us",
  },
  {
    name: content.QiranBookBlog,
    link: "/blog",
  },
  {
    name: content.ContactUs,
    link: "/contact-us",
  },
];
export const ListUser = [
  {
    name: content.MyProfile,
    icon: icons.FaUser,
    link: "/profile",
  },
  {
    name: content.Chats,
    icon: icons.PiChatsCircleFill,
    link: "/chat",
    notification_name: "NEW_MESSAGE",
  },
  {
    name: content.WhoVisitedMyAccount,
    icon: icons.BiSolidUserVoice,
    link: "/who-visited-my-account",
    notification_name: "PROFILE_VISITING",
  },
  {
    name: content.ListOfInterest,
    icon: icons.RiUserHeartFill,
    link: "/list-of-interest",
  },
  {
    name: content.ListOfIgnore,
    icon: icons.RiUserForbidFill,
    link: "/list-of-ignore",
    notification_name: "IGNORED",
  },
  {
    name: content.WhoCaresAboutMe,
    icon: icons.BsChatHeartFill,
    link: "/who-cares-about-me",
    notification_name: "CARED",
  },
  {
    name: content.Images,
    icon: icons.IoMdPhotos,
    link: "/user-images/my-images",
    notification_name: "IMAGE_ACCESS",
  },
  {
    name: content.Settings,
    icon: icons.IoMdSettings,
    link: "/settings/",
  },
];

export const ListNavBar = [
  {
    name: content.Home,
    link: "/",
    icon: icons.IoMdHome,
  },
  {
    name: content.Search,
    link: "/search/",
    icon: icons.BiSearch,
  },
  {
    name: content.OnlineMembers,
    link: "/online",
    icon: icons.PiUserCircleFill,
  },
  {
    name: content.NewMembers,
    link: "/new",
    icon: icons.FaUserPlus,
  },
  {
    name: content.LastEnteredMembers,
    link: "/active",
    icon: icons.FaUserCheck,
  },
  {
    name: content.HealthConditions,
    link: "/health-conditions",
    icon: icons.FaHeartbeat,
  },
];
export const ListSideBar = [
  ...ListNavBar,
  {
    name: content.MyProfile,
    icon: icons.FaUser,
    link: "/profile",
  },
  {
    name: content.Chats,
    icon: icons.PiChatsCircleFill,
    link: "/chat",
    notification_name: "NEW_MESSAGE",
  },
  {
    name: content.WhoVisitedMyAccount,
    icon: icons.BiSolidUserVoice,
    link: "/who-visited-my-account",
    notification_name: "PROFILE_VISITING",
  },
  {
    name: content.ListOfInterest,
    icon: icons.RiUserHeartFill,
    link: "/list-of-interest",
  },
  {
    name: content.ListOfIgnore,
    icon: icons.RiUserForbidFill,
    link: "/list-of-ignore",
    notification_name: "IGNORED",
  },
  {
    name: content.WhoCaresAboutMe,
    icon: icons.BsChatHeartFill,
    link: "/who-cares-about-me",
    notification_name: "CARED",
  },
  {
    name: content.Images,
    icon: icons.IoMdPhotos,
    link: "/user-images/",
    notification_name: "IMAGE_ACCESS",
  },
  {
    name: content.Settings,
    icon: icons.IoMdSettings,
    link: "/settings/",
  },
  { br: true },
  {
    name: content.QiranBookapplication,
    link: "/",
  },
  {
    icon: icons.BsBook,
    name: content.QiranBookBlog,
    link: "/blog",
  },
  {
    icon: icons.IoMdContact,
    name: content.ContactUs,
    link: "/contact-us",
  },
  {
    icon: icons.MdQuestionAnswer,
    name: content.CommonQuestions,
    link: "/faqs",
  },
  {
    icon: icons.IoMdHelpCircle,
    name: content.Help,
    link: "/help",
  },
  {
    name: content.AboutUs,
    link: "/about-us",
    icon: icons.FaCircleExclamation,
  },
  { br: true },
];
export const ListSideBarNoSign = [
  ...ListNavBar,
  {
    name: content.SignUp,
    link: "/auth/sign-up",
  },
  {
    name: content.SignIn,
    link: "/auth/sign-in",
  },
  { br: true },

  {
    icon: icons.BsBook,
    name: content.QiranBookBlog,
    link: "/blog",
  },
  {
    icon: icons.IoMdContact,
    name: content.ContactUs,
    link: "/contact-us",
  },
  {
    icon: icons.MdQuestionAnswer,
    name: content.CommonQuestions,
    link: "/faqs",
  },
  {
    icon: icons.IoMdHelpCircle,
    name: content.Help,
    link: "/help",
  },
  {
    name: content.AboutUs,
    link: "/about-us",
    icon: icons.FaCircleExclamation,
  },
  { br: true },
];

export const navFooterMobile = [
  {
    name: content.Home,
    link: "/",
    icon: icons.IoMdHome,
  },
  {
    name: content.Search,
    link: "/search/",
    icon: icons.BiSearch,
  },
  {
    name: content.Online,
    link: "/online",
    icon: icons.PiUserCircleFill,
  },
  {
    name: content.NewMembers,
    link: "/new",
    icon: icons.FaUserPlus,
  },
];
export const dataUserOperations = [
  {
    path: "who-visited-my-account",
    api: "v1/profile/visitors",
    title: "WhoVisitedMyAccount",
  },
  {
    path: "list-of-interest",
    api: "v1/care/caring",
    actionApi: "care",
    title: "ListOfAttention",
  },
  {
    path: "list-of-ignore",
    api: "v1/ignore/ignoring",
    actionApi: "ignore",
    title: "ListOfIgnoring",
  },
  {
    path: "Who-cares-about-me",
    api: "v1/care/cared",
    title: "WhoCaresAboutMe",
  },
];

export const TechnicalSupport = [
  {
    name: content.Help,
    link: "/help",
  },
  {
    name: content.CommonQuestions,
    link: "/faqs",
  },
  {
    name: content.SiteTermsAndConditions,
    link: "/site-terms",
  },
  {
    name: content.PrivacyPolicy,
    link: "/privacy-policy",
  },
];
export const MemberListFooter = [
  {
    name: content.AllMembers,
    link: "/members",
  },
  {
    name: content.AdvancedSearch,
    link: "/search/",
  },
  {
    name: content.OnlineMembers,
    link: "/online",
  },
  {
    name: content.NewMembers,
    link: "/new",
  },
  {
    name: content.HealthConditions,
    link: "/health-conditions",
  },
  {
    name: content.LastEnteredMembers,
    link: "/active",
  },
];
export const avatars = [
  { img: favatar_1, id: 1, type: "FEMALE" },
  { img: favatar_2, id: 2, type: "FEMALE" },
  { img: favatar_3, id: 3, type: "FEMALE" },
  { img: favatar_4, id: 4, type: "FEMALE" },
  { img: mavatar_1, id: 5, type: "MALE" },
  { img: mavatar_2, id: 6, type: "MALE" },
  { img: mavatar_3, id: 7, type: "MALE" },
  { img: mavatar_4, id: 8, type: "MALE" },
];
export const maleavatar = [];
