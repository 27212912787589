import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";

import {
  MemberListFooter,
  TechnicalSupport,
  WhoWeAre,
  navFooterMobile,
} from "../../Context/lists";
import { language, useFETCH } from "../../Tools/APIs";
import { content } from "../../Context/translate";
import { Col, Container, Row } from "../../Tools/Grid-system";
import google from "../../images/a4c4ac5e92bd4e2f57f6.png";
import app from "../../images/af4ee7777207b062d970.png";
import Logo from "../../images/qiranbook6 (1) 1.svg";
const Footer = () => {
  const { pathname } = useLocation();
  const { data } = useFETCH("v1/info/all");
  const socialMediaLinks = data?.data?.data;

  const appLinks = [
    {
      name: "google",
      image: google,
      link: socialMediaLinks && socialMediaLinks["google_play_url"],
    },
    {
      name: "app",
      image: app,
      link: socialMediaLinks && socialMediaLinks["app_store_url"],
    },
  ];

  const socialMediaIcons = [
    {
      name: "twitter",
      element: (
        <FaTwitter
          size={40}
          className="text-gray-500 cursor-pointer hover:text-blue-500 transition-all duration-300"
        />
      ),
      link: socialMediaLinks && socialMediaLinks["twitter"],
    },
    {
      name: "instagram",
      element: (
        <FaInstagram
          size={40}
          className="text-gray-500 cursor-pointer hover:text-pink-600 transition-all duration-300"
        />
      ),
      link: socialMediaLinks && socialMediaLinks["instagram"],
    },
    {
      name: "facebook",
      element: (
        <FaFacebookF
          size={40}
          className="text-gray-500 cursor-pointer hover:text-blue-600 transition-all duration-300"
        />
      ),
      link: socialMediaLinks && socialMediaLinks["facebook"],
    },
    {
      name: "youtube",
      element: (
        <FaYoutube
          size={40}
          className="text-gray-500 cursor-pointer hover:text-red-700 transition-all duration-300"
        />
      ),
      link: socialMediaLinks && socialMediaLinks["youtube"],
    },
  ];

  return (
    <div className="shadow-black mt-16 shadow-2xl">
      <Container
        className={` ${
          pathname.substring(0, 5) === "/chat" ? "hidden" : "max-md:hidden"
        }`}
      >
        <Row className="flex flex-row-reverse">
          <Col xl={8}>
            <Row className="footer__links !px-3 !py-4">
              <Col md={4} xs={6} className="flex items-center flex-col">
                <h4 className="text-Main text-3xl">{content.WhoWeAre}</h4>
                <ul
                  className={`space-y-2 mt-3 list-disc leading-tight marker:text-Main marker:text-3xl ${
                    language === "ar" && "text-right"
                  }`}
                >
                  {WhoWeAre.map((e, i) => (
                    <li key={i}>
                      <Link className="hover:text-Main" to={e.link}>
                        {e.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={4} xs={6} className="flex items-center flex-col">
                <h4 className="text-Main text-3xl">
                  {content.TechnicalSupport}
                </h4>
                <ul
                  className={`space-y-2 mt-3 list-disc leading-tight marker:text-Main marker:text-3xl ${
                    language === "ar" && "text-right"
                  }`}
                >
                  {TechnicalSupport.map((e, i) => (
                    <li key={i}>
                      <Link
                        style={{ direction: "ltr" }}
                        className="hover:text-Main"
                        to={e.link}
                      >
                        {e.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={4} xs={6} className="flex items-center flex-col">
                <h4 className="text-Main text-3xl">{content.MemberLists}</h4>
                <ul
                  className={`space-y-2 mt-3 list-disc leading-tight marker:text-Main marker:text-3xl ${
                    language === "ar" && "text-right"
                  }`}
                >
                  {MemberListFooter.map((e, i) => (
                    <li key={i}>
                      <Link className="hover:text-Main" to={e.link}>
                        {e.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xl={4}>
            <div className="flex justify-center flex-col">
              <div className="inline text-center">
                <Link to="/" className="inline-block">
                  <img src={Logo} alt="" className="mx-auto" width={200} />
                </Link>
              </div>
              <div className="text-center font-semibold -mt-4 mb-4">
                <p>{content.FooterDesc}</p>
                <p>{content.explaination}</p>
              </div>
            </div>
            <div className="mx-auto w-fit flex items-center gap-3">
              {socialMediaLinks &&
                appLinks.map((e) => (
                  <a key={e.name} href={e.link}>
                    <img
                      src={e.image}
                      alt={e.name}
                      className="max-sm:w-[120px]"
                      width={150}
                    />
                  </a>
                ))}
            </div>
          </Col>
        </Row>
        <Container>
          <div className="flex justify-center items-center gap-10">
            {socialMediaLinks &&
              socialMediaIcons.map((e) => (
                <a key={e.name} href={e.link}>
                  {e.element}
                </a>
              ))}
          </div>
        </Container>
      </Container>
      <Row
        className={`items-center text-2xl shadowTop py-2 !mx-0 overflow-hidden max-md:h-[10vh] md:!hidden sticky left-0 bottom-0 z-50 w-full bg-white border-t `}
      >
        <div sm={2} className="flex items-center justify-around">
          {navFooterMobile.map((e, i) => (
            <NavLink key={i} to={e.link}>
              <div className="flex flex-col items-center justify-center gap-1 hover:text-Main transition-all duration-300">
                <e.icon size={25} />
                <p className="text-sm">{e.name}</p>
              </div>
            </NavLink>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default Footer;
