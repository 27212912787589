import React, { useEffect, useState } from "react";
import { BiHeart, BiTrash } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { Cards, ImageUser, TitlePage } from "../../components";
import PopUp from "../../components/PopUps/PopUp";
import { useContextChat } from "../../Context/ContextChat";
import { useContextHook } from "../../Context/ContextOPen";
import { ageVoid } from "../../Context/functions";
import { avatars } from "../../Context/lists";
import { content } from "../../Context/translate";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { BsHeartFill } from "react-icons/bs";

const UserOperations = ({ api, title, actionApi, isChat }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { setShowBottomSheet, setShowPopUpUser, setMessageActivate } =
    useContextHook();
  const { search } = useLocation();
  const [users, setUsers] = useState([]);
  const { chatDelelete, isActive } = useContextChat();
  const { data, isLoading, successfulDelete, deleteItem } = useFETCH(
    `${api}${isChat ? "" : search}`
  );
  useEffect(() => {
    if (isChat)
      if (data?.data?.data?.data?.length > 0) {
        setUsers([...data?.data?.data?.data]);
      }
  }, [data?.data?.data?.data]);
  useEffect(() => {
    if (isChat)
      if (chatDelelete) {
        setUsers(users?.filter((p) => +p.id !== +chatDelelete));
      }
  }, [chatDelelete]);
  useEffect(() => {
    if (isChat)
      if (successfulDelete) {
        setUsers(users?.filter((p) => +p.id !== +successfulDelete));
      }
  }, [successfulDelete]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  if (isLoading) return <Loading />;

  return (
    <>
      <Col md={11} className="mx-auto space-y-4">
        <TitlePage title={title} />
        <Col md={12} className="mx-auto">
          <Pagination
            pageCount={Math.ceil(
              data?.data?.data?.total / data?.data?.data?.per_page
            )}
          >
            <Cards>
              <div className="flex items-center justify-around text-Secondary font-bold text-xl max-md:text-base px-5 mb-4">
                {/* <Col col={7}>
                  <p>{content.MemberName}</p>
                </Col>
                <Col col={3}>
                  <p className="mx-auto">
                    {actionApi ? content.Action : content.lastmessagecontent}
                  </p>
                </Col> */}
                {isChat && <Col md={1} col={2}></Col>}
              </div>
              {data?.data?.data?.data?.length > 0 ? (
                data?.data?.data?.data?.map((e) =>
                  isChat ? (
                    <>
                      {showConfirmation && (
                        <PopUp
                          show={showConfirmation && !successfulDelete}
                          onClickFalse={() => {
                            setShowConfirmation(false);
                          }}
                          onClick={() =>
                            deleteItem(e.id, `v1/chat/users/${e.id}`)
                          }
                          title={content.MessageDeleteChat}
                        />
                      )}
                      <Row gap={2} className="items-center !m-0 !mb-3">
                        <Col
                          md={10}
                          col={9}
                          onClick={() => {
                            if (!isActive) setMessageActivate(true);
                            else setShowBottomSheet([e.id, true]);
                          }}
                        >
                          <Col
                            col={12}
                            className="flex items-center justify-between gap-2 cursor-pointer mb-0"
                          >
                            <div className="flex justify-center items-center gap-2">
                              <ImageUser
                                isChat={isChat}
                                image={
                                  e.images.length > 0
                                    ? fileUrl +
                                      e.images[e.images.length - 1].image
                                    : e.avatar_id
                                    ? avatars.filter(
                                        (ele) => ele.id === e.avatar_id
                                      )[0]?.img
                                    : ""
                                }
                                id={e.id}
                                className="!w-12 !h-12"
                              />

                              <div className=" flex  space-x-1  max-sm:text-sm flex-wrap ">
                                <p className="max-sm:!w-full">
                                  <span className="w-full text-Secondary font-bold">
                                    {e.name.length !== 0 && windowWidth <= 500
                                      ? e.name.substring(0, 10) + "..."
                                      : e.name}
                                  </span>
                                  <br />
                                  {/* <span className="w-full text-Main font-semibold">
                                      {e.age} {content.years}{" "}
                                    </span> */}
                                  <span className="text-base max-sm:text-sm font-semibold text-Main   ">
                                    {e.age} {content.years}{" "}
                                  </span>{" "}
                                </p>
                              </div>
                            </div>
                            <div>
                              <p className="font-semibold text-base max-sm:text-sm text-start w-full  ">
                                {e.last_message_time.split(" ")[0]}
                              </p>
                            </div>
                          </Col>
                          <Col lg={6} className="mx-auto ">
                            <p className="opacity-70 max-sm:text-sm text-center font-extrabold text-gray-500 text-lg my-1 border border-gray-300 rounded-3xl p-1  mx-auto">
                              {e.last_message_content.length > 30
                                ? e.last_message_content.substring(0, 30) +
                                  "..."
                                : e.last_message_content}
                            </p>
                          </Col>
                          {/* <Col md={11} col={12} className="cursor-pointer">
                            <p className="font-semibold text-base max-sm:text-sm text-end w-full ">
                              {e.last_message_time.split(" ")[0]}
                            </p>
                          </Col> */}
                        </Col>
                        <Col md={1} col={2} className="flex justify-end">
                          <BiTrash
                            color="red"
                            size={40}
                            className="cursor-pointer p-2 rounded-full hover:bg-slate-100"
                            onClick={() => setShowConfirmation(true)}
                          />
                        </Col>
                        <div className=" h-[0.5px] bg-gray-300 !w-[90%] mx-auto my-2 "></div>
                      </Row>
                    </>
                  ) : (
                    <Card
                      key={e.id}
                      image={
                        e.images.length > 0
                          ? fileUrl + e.images[e.images.length - 1].image
                          : e.avatar_id
                          ? avatars.filter((ele) => ele.id === e.avatar_id)[0]
                              .img
                          : ""
                      }
                      name={e.secret_data?.name}
                      status={e.social_status?.social_status?.name}
                      age={e.social_status?.age}
                      id={e?.login_details?.id}
                      from={e.nationality_residence?.city?.name}
                      actionApi={actionApi}
                      time={e.pivot_created_at}
                      onClick={() =>
                        setShowPopUpUser([e?.login_details.id, true])
                      }
                    />
                  )
                )
              ) : (
                <div className="flex justify-center items-center">
                  <h1 className="text-gray-700 text-2xl font-bold">
                    {content.noData}
                  </h1>
                </div>
              )}
              {isChat && (
                <div className="flex mt-4">
                  <Col>
                    <Link to={"/settings/chat"}>
                      <button className="px-4 py-2  text-white rounded-md text-lg max-md:text-base max-sm:text-sm font-semibold bg-Main hover:bg-[#d9788d]">
                        {content.MessagesSettings}
                      </button>
                    </Link>
                  </Col>
                </div>
              )}
            </Cards>
          </Pagination>
        </Col>
      </Col>
    </>
  );
};

export default UserOperations;

const Card = ({
  image,
  name,
  status,
  age,
  from,
  id,
  actionApi,
  time,
  onClick,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const { handleSubmit } = usePOST({});
  const { deleteItem } = useFETCH("", `v1/${actionApi}/${id}/delete`);
  const [success, setSuccess] = useState(true);
  const { setMessagesSuccess } = useContextHook();
  return (
    <div className="flex items-center justify-between px-4 flex-wrap mt-4">
      <div
        className="flex gap-5 items-center pt-2  cursor-pointer"
        onClick={onClick}
      >
        <ImageUser id={id} image={image} className="w-12 h-12" />
        <div>
          <p>
            <span className="font-extrabold max-md:text-sm text-Secondary md:text-lg ">
              {name.length !== 0 && windowWidth <= 500
                ? name.substring(0, 10) + "..."
                : name}
            </span>{" "}
            <br />
            <span className="font-semibold max-md:text-sm text-gray-400 md:text-base ">
              {status} {ageVoid(age)} - {from}
            </span>
          </p>
        </div>
      </div>
      <div className="flex justify-end  items-center text-sm font-semibold sm:ml-[10%] text-Main ">
        {actionApi ? (
          <BsHeartFill
            onClick={() => {
              if (success) {
                deleteItem(id, `v1/${actionApi}/${id}/delete`).then(() => {
                  setSuccess(!success);
                  setMessagesSuccess([
                    actionApi === "ignore"
                      ? content.MessageUnblocked
                      : content.MessageRemovedFavorites,
                  ]);
                });
              } else {
                handleSubmit(
                  `v1/${actionApi}?${actionApi}d_id=${id}`,
                  "",
                  true
                ).then(() => {
                  setSuccess(!success);
                  setMessagesSuccess([
                    actionApi === "ignore"
                      ? content.MessageBlocked
                      : content.MessageAddedFavorites,
                  ]);
                });
              }
            }}
            className={`${
              success ? "fill-Main" : "fill-slate-300"
            } !w-6 !h-6  flex justify-center cursor-pointer transition-colors`}
          />
        ) : (
          time
        )}
      </div>
      <div className=" h-[0.5px] bg-gray-300 !w-[90%] mx-auto my-2 "></div>
    </div>
  );
};
