import React from "react";
import { Link, useLocation } from "react-router-dom";
import { content } from "../Context/translate";
import { language } from "../Tools/APIs";
import SubscribeIcon from "../Svg/subscribe.svg";

export default function PackageLink() {
  const { pathname } = useLocation();
  return (
    <Link
      to={`/packages`}
      className={`p-2 flex flex-col gap-2 shadow border border-blue-700 hover:bg-blue-900 transition-colors ${
        pathname === "/packages" ? "hidden" : ""
      } flex justify-center items-center text-white fixed text-sm z-50 ${
        language === "ar"
          ? "left-4 bottom-[12%] sm:left-8"
          : "right-4 bottom-[12%] sm:right-8"
      }  md:bottom-8 w-28 h-28 bg-blue-700 aspect-square rounded-full`}
    >
      <img src={SubscribeIcon} className="w-8 fill-white" />
      <p>{content.Subscribe}</p>
    </Link>
  );
}
